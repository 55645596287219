















import { Component, Vue } from 'vue-property-decorator'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import Common from '../common/Common'

@Component({
  components: {
    ClipLoader
  },
})
export default class Login extends Vue {
  isLoading = true
  errText = ''

  mounted() {
    if (!Common.useWebRTC()) {
      this.$router.push('/unsupport')
      return
    }

    sessionStorage.removeItem('user')
    sessionStorage.removeItem('category')
    firebase.auth().signOut()
    var uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: (currentUser: any, credential: any, redirectUrl: any) => {
          Common.getUserInfo().then((userInfo) => {
            if (userInfo) {
              if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect as string);
                return true
              }
              this.$router.push('/home')
              return true
            }
            if (this.$router.currentRoute.path === '/clinic/login') {
              this.$router.push('/clinic-setting')
              return true
            }
            this.$router.push('/user-setting')
            return true
          })
        },
        signInFailure: (error: any) => {
          this.errText = 'ログインに失敗しました'
          this.isLoading = false
        },
        uiShown: () => {
          this.setLoadingBoxHidden()
        }
      },
      signInOptions: [{}],
    };
    // クリニックログインとログインの出し分け
    uiConfig.signInOptions.push(firebase.auth.EmailAuthProvider.PROVIDER_ID);
    uiConfig.signInOptions.push(firebase.auth.GoogleAuthProvider.PROVIDER_ID);
    if(this.$route.name == "login"){
      uiConfig.signInOptions.push(firebase.auth.FacebookAuthProvider.PROVIDER_ID);
      uiConfig.signInOptions.push(firebase.auth.TwitterAuthProvider.PROVIDER_ID);
    }

    var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
    ui.start('#firebaseui-auth-container', <any> uiConfig);
  }

  setLoadingBoxHidden() {
    setTimeout(() => {
      if (document.getElementsByClassName('firebaseui-card-content').length != 0) {
        this.isLoading = false
      } else {
        this.setLoadingBoxHidden()
      }
    }, 300)
  }
}
